.c-header-navigation {
  &.-dark{
  .page-restaurant-jobs &,
    .page-corporate-search &,
    .page-jobs &,
    #job-detail &,
    [data-page-name="Find jobs by location"] &,
    #location-description &  {
      .c-header-navigation__link {
        color: #333;
        &:hover {
          color: #333;
        } 
      }
    }

    // .page-mission-and-values & {
    //   .c-header-navigation__link.-cta-btn {
    //     &:hover {
    //       color: #fff;
    //     } 
    //   }
    // }
    .c-header-navigation__link {
      color: $accent-3;
      &:hover {
        color: $accent-3;
      } 
    }

  }

  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  height: auto;

  &__content {
    top: 0;
    left: 0;
    right: 0;
    position: relative;
    height: 6rem;
    color: #333;
    // background-color: rgba(255, 255, 255, 0.15);

   

    .has-nav & {
      @media (min-width: $from-medium) {
        position: absolute;
        width: 6rem;
        top: -6rem;
        right: 0;
        left: auto;
      }
    }

    &::before {

      @media (min-width: $from-tiny) {
        content: "";
        opacity: 0.5;
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        background-color: $border-line;
        height: 1px;
        width: 100%;
      }


    }

    @media (min-width: $from-medium) {
      height: 6rem;
    }

    @media (max-width: $to-tiny) {
      .has-scrolled & {
        border-color: transparent;
        background-color: transparent;
      }

      transition: 0.3s all ease-in-out;
      border-radius: 0rem 0rem 2rem 2rem;
      


    }
  }

  &__logo {
    position: relative;
    width: 6rem;
    display: inline-block;
    height: 100%;
    text-align: center;
    font-size: 0;
    pointer-events: auto;

    @media (min-width: $from-small) {
      width: 6rem;
    }

    &::before {
      content: "";
      height: 100%;
      display: inline-block;
      vertical-align: middle;
    }

    &:hover {
      cursor: pointer;
      
          &::after {
           
        
             
              opacity: 1;
              visibility: visible;
              cursor: default;

              
            
          }
    }

    &::after {

      content: '';
      background-image: url('../../assets/images/home/bubbles.svg');
        transition: 0.3s all ease-in-out;
        opacity: 0;
        position: absolute;
             
        left: rem(30px);
        bottom: rem(-120px);
        visibility: hidden;
        background-size: contain;
        width:  rem(150px);
        height: rem(150px);
    }


    &--desktop {
      fill: #fff;
      width: auto;
      height: 3.375rem;
    

      @media (min-width: $from-tiny) {
        display: inline-block;
        width: 20rem;
        height: 3rem;
      }


    }

   
  }

  &__search-btn {
    display: none;
    @media (min-width: $from-medium) {
      display: inline-block;
      position: absolute;
      height: 6rem;
      right: 10rem;
    }

    & > div {
      height: 100%;
      display: flex;
      align-items: center;
    }

  }

  &__nav {
    display: none;

    @media (min-width: 1250px) {
      display: inline-block;
      position: absolute;
      color: #fff;
      top: 0;
      left: 6rem;
      padding-right: 6rem;
      height: 6rem;
      // width: calc(100% - 6rem)
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    li:hover>ul {
      opacity: 1;
      visibility: visible;
      background:$accent-2;
      border-radius: 10px;
      top: 6rem;
      display: block;
      column-count: 2;
      // display: flex;
      // flex-flow: wrap;

      &::before {
        opacity: 1;
      visibility: visible;
      }

     
    }

    ul {
      width: 100%;
      opacity: 0;
      padding: 0;
      left: 0;
      list-style: none;
      position: absolute;
      top: 160%;
      visibility: hidden;
      transition: opacity 0.5s ease-in-out;
      // transition: all 0.4s ease;
      // -webkit-transition: all 0.4s ease;
      &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: rem(-38px);
        width: 50%;
        right: 10%;
        height: rem(40px);
        background-image: url('../../assets/images/gooe.svg');
        background-repeat: no-repeat;
        background-size: contain;
        // transition: all 0.4s ease;
        // visibility: hidden;
        opacity: 0;
      }
    }

    .full-list ul {
      width: 33rem;
      left: 0;
      overflow: hidden;
    }
  }

  &__item {
    margin: 10px 0;
    display: flex;
    align-items: center;
    // position: relative;
    height: auto;
    height: 100%;

    &.-last {
      padding: 0 1.2vw;
    }

    // &::before {
    //   content: '01';
    //   display: block;
    //   position: relative;
    //   font-size: rem(12px);
    //   color: $accent;
    //   font-weight: bold;
    // }

    // &:nth-child(2) {
    //   &::before {
    //     content: '02';
    //   }
    // }

    // &:nth-child(3) {
    //   &::before {
    //     content: '03';
    //   }
    // }

    // &:nth-child(4) {
    //   &::before {
    //     content: '04';
    //   }
    // }

    // @include last-child() {
    //   &::before {
    //     content: '03';
    //   }
    // }

    &-submenu {
      ul {
        top: 0%;
        left: 160%;
      }

      li {
        display: inline-block;
        position: relative;
        width: 100%;
      }

      li:hover>ul {
        top: 0%;
        left: 100%;
        opacity: 1;
        visibility: visible;
      }

      a {
        text-decoration: none;
        
        display: block;
        color: $color;
        font-weight: bold;
        font-size: 16px;
        padding: 10px 15px;
        text-align: left;
        border-bottom:solid 3px transparent;
        text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.2);
        transition: color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s, transform 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);

        &:hover {
         color: $accent;
        }

        span {
          float: right;
        }
      }
    }
  }

  &__link {
    font-family: $font-default;
    font-weight: bold;
    color: #fff;
    position: relative;
    display: inline-block;
    padding: 0 1.2vw;
    font-size: rem(16px);
    line-height: 1;

   
    

    &:focus {
      color: #fff;
    }

    &.active:not(.-cta-btn)::after {
      border-bottom: 3px solid $accent;
      transform: scaleX(1);
      transform-origin: center left;
    }

    &.has-drop {
      display: flex;
      align-items: center;

      .c-header-navigation__item-drop {
        width: rem(20px);
        height: auto;
        display: inline-block;
        margin-left: 0.5rem;

        svg {
          width: rem(20px);
          height: rem(20px);
        }
      }
    }

    &:not(.-cta-btn)::after {
      content: "";
      width: 100%;
      margin: 0 auto;
      position: absolute;
      right: 0;
      bottom: rem(-40px);
      left: 0;
      transform: scaleX(0);
      transition: transform 0.3s cubic-bezier(0.4, 0, 0, 1);
      transform-origin: center right;
      border-bottom: 3px solid $accent;

      .has-drop & {
        display: none;
      }
    }

    &:not(.-cta-btn):hover {
      color: #fff;

      &::after {
        transform: scaleX(1);
        transform-origin: center left;
      }
    }

    &.-cta-btn {
      border-radius: 2rem;
      border: solid 2px $border-line;
      height: auto;
      padding: 0 0 0 rem(25px);
      display: flex;
      align-items: center;
      justify-content: space-around;
      position: relative;
      transition: background-color 0.3s cubic-bezier(0.4, 0, 0, 1);

      &:hover {
        color: #fff;

        .o-button__icon {
          background-color: $accent-2;
        }
      }
    }

    &-icon {
      svg {
        stroke: #fff;
        stroke-width: rem(2px);
        width: rem(30px);
        height: rem(30px);
      }
    }
  }

  &__phone {
    position: relative;
    display: inline-block;
    margin-right: 0.5rem;
    width: 1.645rem;
    height: 1.645rem;
  }

  &__menu {
    height: 100%;
    text-align: center;
    font-size: 0;
    pointer-events: auto;
    transition: opacity 0.3s cubic-bezier(0.4, 0, 0, 1), visibility 0s;
    float: right;
    width: 20%;

    @media (min-width: $from-small) {
      width: 6rem;
    }

    @media (max-width: $to-tiny) {
      .has-scrolled & {
        background: $accent-2;
        border-radius: 0 0 0 10px;
      }
    }

    &-wrap {
      height: 100%;
    }
  }

  &__button {
    width: 2rem;
    position: relative;
    z-index: 1001;
    font-size: 0 !important;

    &::before {
      opacity: 0;
      content: "menu";
      display: block;
      position: absolute;
      top: -1.3rem;
      width: 100%;
      color: #fff;
      height: 100%;
      left: 0;
      text-transform: uppercase;
      font-size: 10px;
      transition: background-color 0.6s linear,
        opacity 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s,
        transform 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s;
    }

    // display: flex;
    // align-items: center;
    // justify-content: center;
    // width: auto;
    // &::before {
    //   opacity: 1;
    //   content: 'Menu';
    //   display: block;
    //   position: relative;
    //   width: 100%;
    //   color: #fff;
    //   height: 100%;
    //   font-size: rem(12px);
    //   transition: background-color 0.6s linear,
    //     opacity 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s,
    //     transform 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.3s;
    // }

    &-line {
      display: inline-block;
      width: 33px;
      height: 2px;
      transition: all 0.4s cubic-bezier(0.43, 0.195, 0.02, 1);
      transform-origin: 100% 100%;
      transform: translateY(-3%);
      background-color: #fff;
      .-dark & {
        background-color: $accent-3;

        // .page-jobs & {
        //   background-color: #fff;
        // }
      }
      @include first-child() {
        background-color: $accent-2;
      }
      @include last-of(3) {
        transform: scaleX(0.5);
        background-color: $accent-2;
      }

      @include middle(3) {
        margin: 7px 0px;

        // .page-mission-and-values &,
        .page-restaurant-jobs &,
        .page-jobs &,
        .page-corporate-search &,
        #job-detail &,
        [data-page-name="Find jobs by location"] &,
        #location-description &   {
          // background-color: #fff;
        }

        // .page-mission-and-values & {
        //   @media(max-width: $to-medium) {
        //     background-color: $accent-3
        //   }
        // }
      }
    }

    &:hover {
      .c-header-navigation__button-line {
        @include last-of(3) {
          transform: scaleX(1);
        }
      }
    }
  }

  &.has-nav-open {
    .c-header-navigation__button-line {
      &:nth-child(1) {
        // transition-delay: 0s, 0.2s;
        transform: rotate(-45deg);
      }

      &:nth-child(2) {
        // transition-delay: 0.1s;
        transform: rotate(45deg) translate(10px, 10px);
      }

      &:nth-child(3) {
        opacity: 0;
        // transition-delay: 0s, 0.1s;
        transform: translateY(-0.59375rem);
      }
    }

    .o-overlay {
      opacity: 1;
      visibility: visible;
    }

    .c-mega-menu {
      transform: translate3d(0, 0, 0);

      @media (min-width: $from-small) {
        right: 0;
      }
    }
    .c-header-navigation__nav {
      opacity: 0;
    }
  }
}

.o-overlay {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.35);
  transition:0.4s all ease-in-out
}