.c-blurb {
  position: relative;
  display: block;
  margin-top: rem(120px);

  .swiper-pagination {
    position: relative;

    @media(min-width: $from-medium) {
      text-align: left;
      // bottom: rem(-100px);
    }

    .swiper-pagination-bullet-active {
      background-color: $accent;
    }
  }

  .c-bubbles {
    position: absolute;
    right: 0;
    top: rem(-300px);
    width:  rem(400px);
    height: rem(400px);
    z-index: 1;
    will-change: transform;
    svg {
      width: 100%;
      height: 100%;
    }
  }

}

.c-blurb-image {
  position: relative;
  margin-top: rem(120px);
  &__background {

    &-wrapper {
      position: relative;
      @media(max-width:$to-medium) {
        width: calc(100% + 18rem);
        left: -9rem;
        margin-top: rem(120px);
      }
      &:before {
        content: '';

        display: block;
        
        background-color: $accent;
        width: rem(200px);
        height: rem(200px);
        position: absolute;
        top: rem(-30px);
        right: rem(-30px);
        border-radius: 10px;
        
        
      }

      &.-simple {
        
        &::before {
          display: none;
        }
        .c-blurb-image__background {
          height: rem(200px);
          margin-bottom: rem(30px);
        }
      }
    }

    
    width: 100%;
    height: rem(300px);
    position: relative;
    border-radius: 0;
    overflow: hidden;
    @media(min-width: $from-medium) {
      border-radius: 10px;

      

    }

    @media(min-width: $from-big) {
      height: rem(400px);
    }
  }
}

#accommodation-scrollto {
  @media(min-width: $from-medium) {
    margin-bottom: rem(120px);

  }
}

.c-blurb-slider {

  &__inner {
    margin-bottom: rem(90px);
  }
  // margin-bottom: rem(120px);
  // width: calc(100% + 18rem);
  // left: -9rem;
  
  position: relative;

  // @media(min-width: $to-medium) {
  //   width: calc(100% + 18rem);
  //   left: -9rem;
  // }

  &__main {
    // padding: rem(40px);
    @media(min-width: $from-medium) {
      overflow: visible;

    }

  }

  &__item {
    // overflow: hidden;
    padding: rem(70px) 0;
    // margin: 2rem auto;
  }
  &__content {
    display: flex;
    align-items: center;
    text-align: center;
    padding: rem(30px);
    justify-content: center;
    border-radius: 5px;
    background-color: #fff;
    flex-flow: column;
    
    @media(min-width: $from-medium) {
      box-shadow: 0 0 20px 20px rgba(0, 0, 0, 0.03);
      flex-flow: row;
      text-align: left;
    }

    

    &-image {
      position: relative;
      text-align: center;
      @media(min-width: $from-medium) {
        
        width: 30%;
        margin-right: rem(60px);
      }
      img{
        width: rem(100px);
      }
    }

    &-text {
      width: 70%;
      h3 { margin-bottom: 0;}
      p {font-weight: bold;}
    }
  }

 
}


#corpjobs {
  margin-top: rem(120px);
  position: relative;
}

.c-tag {
  
    bottom: 0;
    
    z-index: 1;
    background-color: $accent-2;
    padding: rem(10px);
    font-weight: bold;
    border-radius: 0 5px 0px 0;
    position: relative;
    text-align: center;

    @media(min-width: $from-small) {
      left: 0;
      position: absolute;
    }

}