// ==========================================================================
// Objects / Background
// ==========================================================================

.o-image {
    width: 100%;
    position: relative;

    overflow: hidden;

    &::before {
        content: "";
        display: block;
        padding-bottom: 75%;
    }
}



.o-image__title {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center;
    color: white;
    text-transform: uppercase;
    z-index: 2;
    font-size: 7vw;
    transform: translateY(-50%);
    font-weight: bold;
    margin: 0;

    span {
        display: inline-block;
        min-width: 0.1em;
        padding: 10vh 0;
    }
}

.o-image__background {
    position: absolute;
    top: -25%;
    bottom: -25%;
    right: 0;
    left: 0;
    background-size: cover;
    background-position: center center;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.3);
    }
}



.o-fixed__wrapper {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.o-fixed {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}

.o-fixed__target {
    display: block;
    position: absolute;
    top: -100vh;
    right: 0;
    left: 0;
    height: 200vh;
}

.o-background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: center center;
    background-size: cover
}


.o-background__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;

    &-full {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-position: center center;
        background-size: cover;
        opacity: 0;

        &.-zoom {
            top: -25%;
            bottom: -25%;
            
        }

        &.-zoom-small {
            top: -10%;
            bottom: -10%
        }

        &.-zoom-medium {
            top: -15%;
            bottom: -15%
        }

        
        &.-lazy-loaded {
            animation: 0.6s cubic-bezier(0.2, 0.85, 0.45, 1) 0s 1 normal forwards running ffSPMP;
            
        }
        
        &.-transition {
            animation: image 10s infinite;
        }
        &.cross-fade {
            background-size: cover;
  background: url("../../assets/images/values/cross/img-1.jpg") no-repeat center center;
  opacity: 1;
  transition: 3s;
        }
    }
}

@keyframes image {
    0% {
      background-image: url('../../assets/images/home/6.jpg');
      opacity: 1;
    }
    50% {
        background-image: url('../../assets/images/home/6-1.jpg');
        opacity: 1;
      }
    100% {
      background-image: url('../../assets/images/home/6.jpg');
      opacity: 1;
    }
  }
@keyframes ffSPMP {
    to {
        opacity: 1;
    }
}