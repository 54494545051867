// ==========================================================================
// Objects / Buttons
// ==========================================================================
.o-button {
  &.-long-btn {
    @media(max-width: 360px) {
      max-width: 100%;

      .o-button__label {
        width: 80%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

  }

  &.-dark {
    color: $bg;

    &:hover {
      color: $bg;
    }
  }


  &.-fill {
    background-color: $accent-2;
    color: $color;
    border-color: transparent;
    border-radius: rem(10px);


    @media(min-width: $to-small) {
      .is-chrome & {
        filter: url('#goo');

      }
    }

    .o-button__icon {
    margin-right: rem(5px);
    }


    &:hover {
      color: $color;


      .o-button__icon {
        background-color: $accent;


      }

      @media(min-width: $to-small) {

        .is-chrome & {
          &::before,
          &::after {
            transform: none;
          }

        }

      }

    }

    @media(min-width: $to-small) {
      .is-chrome & {

        &::before,
        &::after {
          width: 4rem;
          height: 2.95em;
          position: absolute;
          content: "";
          display: inline-block;
          background: $accent-2;
          border-radius: 50%;
          transition: transform 1s ease;
          transform: scale(0);
          z-index: -1;
        }
  
        &::before {
          bottom: -30%;
          left: 26%;
          width: 3rem;
        }
  
        &::after {
          bottom: -60%;
          left: 50%;
          width: rem(40px);
        }
      }
    }




  }

  &.-red {
    background-color: $accent;
    color: #fff;
    border-radius: 5px;
    padding: rem(10px) rem(30px);
  }

  
  padding: 0 0 0 rem(25px);
  margin-top: rem(25px);
  border-radius: rem(30px);
  border: 2px solid $border-line;
  color: #fff;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0, 1),
  border 0.3s cubic-bezier(0.4, 0, 0, 1),
  color 0.3s cubic-bezier(0.4, 0, 0, 1);
  position: relative;

  &:hover {
    color: #fff;

    .o-button__icon {
      background-color: $accent-2;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__label {
    font-weight: bold;
    font-size: rem(14px);
  }

  &__icon {
    margin: 3px 3px 3px 1rem;
    background-color: $accent;
    border-radius: 50%;
    color: #fff;
    width: 1.875rem;
    height: 1.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s cubic-bezier(0.4, 0, 0, 1);

    svg {
      width: rem(15px);
      height: rem(15px);
    }
  }

  .deux & {
    @media (min-width: 420px) {
      @include first-child() {
        margin-right: 1rem;
      }
    }
  }

  >svg {
    display: none;

    @media(min-width: $to-small) {
      display: block;
      visibility: hidden;
      position: absolute;

      .is-safari &,
      .is-firefox &,
      .is-edge-chromium &
       {
        display: none
      }
    }
  }


}