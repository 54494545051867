.c-accordion {
  padding: rem(90px) 0;
  display: block;
  position: relative;
  margin-top: rem(60px);

  .-single {
    max-width: 1000px;
    margin: 0 auto;
    display: block;
  }
}

.c-faq {
  // padding: rem(150px) 0;
  z-index: 1;
  position: relative;

  &__background {
    position: relative;
    overflow: hidden;
    right: -7vw;
    width: 100%;
    height: 15rem;

    @media (max-width: $to-medium) {
      display: none;
    }

    @media (min-width: $from-tiny) {
      right: -10vw;
    }

    @media (min-width: $from-small) {
      right: -9rem;
      height: 28rem;
    }

    @media (min-width: $from-medium) {
      height: 30rem;
      right: -6rem;
      width: calc(100% + 3rem);
    }
  }
}

.c-faq-min {
  &__content {
    position: relative;
    padding: rem(150px) rem(30px);
    &::before {
      content: "";
      display: block;
      position: absolute;
      left: -9rem;
      top: 0;
      width: calc(100% + 18rem);
      height: 100%;
      background-color: $accent;
      z-index: -1;
    }
  }
  &__item {
    position: relative;
    width: 100%;
    background-color: $accent-3;
    padding: rem(20px) 0;
    border-radius: 10px;
    margin-bottom: rem(20px);
    &.is-active  {
      background-color: $accent-2;
    }

    &_click__JS {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 1rem;
      @media(min-width: $from-medium) {
        padding: 0 3rem;

      }
    }
  }

  &__title {
    .is-active & {
      color: $accent-3;
    }

    .-single & {
      @media (min-width: $from-small) {
        width: 90%;
      }
    }
    
    @media (min-width: $from-medium) {
      font-size: 20px;
    }
    font-size: 1rem;
    color: #fff;
    font-weight: 700;
    line-height: 1.5;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;

    span {
      display: inline-block;
      color: #fff;
      font-size: 1.4rem;
      margin-right: 1rem;
    }
    width: 60%;

    @media(min-width: $from-small) {
      width: 100%;
    }
  }

  &__btn {
    position: relative;
    // top: 2.22222rem;
    // right: 0;
    width: 2.88889rem;
    height: 2.88889rem;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      width: 1.66667rem;
      height: 0.44444rem;
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
      stroke: $accent;
      -webkit-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;

      [data-component="downloads"] & {
        transform: rotate(0deg);
        width: rem(15px);
        height: rem(15px);
      }
    }
  }
}

.c-faq-min__item.is-active .c-faq-min__btn:after {
  opacity: 1;
  -webkit-transform: scale(1.1) translate3d(0, 0, 0);
  transform: scale(1.1) translate3d(0, 0, 0);
}

.c-faq-min__item.is-active .c-faq-min__btn svg {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  stroke: #fff;
}

html:not(.is-mobile) .c-faq-min__btn:hover:after {
  opacity: 1;
  -webkit-transform: scale(1.1) translate3d(0, 0, 0);
  transform: scale(1.1) translate3d(0, 0, 0);
}

html:not(.is-mobile) .c-faq-min__btn:hover svg {
  stroke: #fff;
}

html:not(.is-mobile) .c-faq-min__btn:hover span {
  color: #fff;
}

.c-faq-min__btn:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  // border: 1px solid #fff;
  background: $accent;
  border-radius: 50%;
  -webkit-transform: scale(0.5) translate3d(0, 0, 0);
  transform: scale(0.5) translate3d(0, 0, 0);
  opacity: 0;
  -webkit-transition: all 0.45s cubic-bezier(0.6, 0.01, 0, 1.38) 0s;
  transition: all 0.45s cubic-bezier(0.6, 0.01, 0, 1.38) 0s;
}

.c-footnote.is-active .c-faq-min__btn:after {
  opacity: 1;
  -webkit-transform: scale(1.1) translate3d(0, 0, 0);
  transform: scale(1.1) translate3d(0, 0, 0);
}

.c-faq-min__item.is-active .c-faq-min__btn:after {
  opacity: 1;
  -webkit-transform: scale(1.1) translate3d(0, 0, 0);
  transform: scale(1.1) translate3d(0, 0, 0);
}

.c-faq-min__bottom {
  height: 0;
  overflow: hidden;
}

.c-faq-min__bottom-ov {
  width: 100%;
  padding: 0 rem(50px);
  margin-top: rem(30px);
}

html:not(.is-mobile) .c-faq-min__btn:hover svg {
  stroke: #fff;
  z-index: 1;
}

.c-faq-min__item.is-active .c-faq-min__btn svg {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  stroke: #fff;
  z-index: 1;
}
