[data-component="olivia-cta"]>.o-container {
  @media(max-width: $to-medium) {
    padding: 0;
  }
}


.c-olivia-cta {

  &.-light {
    background-color: #fff;
    box-shadow: 0px 1px 20px 16px #0000000d;

  }

  position: relative;
  background-color: $accent-3;

  @media(min-width: $from-medium) {
    margin-top: rem(-120px);
    width: 60%;
    border-radius: 30px;
    position: absolute;
    z-index: 1;
  }

  h3 {
    font-size: rem(24px);
  }

  &__inner {
    padding-bottom: rem(60px);
    padding-top: rem(60px);

    @media(min-width: $from-medium) {
      padding: rem(60px);

    }
  }

  .c-bubbles {
    position: absolute;
    right: rem(-230px);
    top: rem(-250px);
    width: rem(400px);
    height: rem(400px);
    will-change: transform;

    @media(min-width: $to-medium) {
      top: rem(120px);
      bottom: 0;

    }

    @media(max-width: $to-medium) {}

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__layout-item {
    display: flex;
    align-items: center;
    flex-flow: column-reverse;

    @media(min-width: $from-small) {
      flex-flow: row;

    }

    .l-layout__content {
      @media(min-width: $to-small) {
        width: 85%;

      }
    }

    .c-olivia-cta__image {
      margin-bottom: rem(60px);
      border: 3px solid $accent-2;
      border-radius: 50%;
      z-index: 1;

      @media(min-width: $from-small) {
        margin-bottom: 0;
        margin-left: rem(30px);
      }

      @media(max-width:$to-small) {
        display: none;
      }

      img {
        max-width: 100%;

        &.-lazy-loaded {
          animation: 0.6s cubic-bezier(0.2, 0.85, 0.45, 1) 0s 1 normal forwards running ffSPMP;

        }
      }
    }
  }


}


.c-cta-banner {
  position: relative;
  background-color: $accent-3;
  z-index: 1;

  .c-bubbles {
    @media(max-width:$to-small) {
      left: 0;
      /* bottom: 0; */
      top: -55%;
    }

    position: absolute;
    right: 0;
    top: rem(-100px);
    width: rem(250px);
    height: rem(250px);
    will-change: transform;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__inner {
    padding: rem(60px);
    text-align: center;

    h2 {
      @media(min-width: $from-medium) {
        margin: 0 auto;

      }
    }

    a {
      margin: 0 auto;
      z-index: 1;
    }
  }

}

.c-blurb-image__content-logo {
  margin-bottom: rem(30px);

  img {
    max-width: 100%;
  }
}