.c-image-box {
  position: relative;
  z-index: 1;

  &.-stripe {
    
    margin-top: rem(290px);
    @media(max-width: $to-small) {
     
      margin-top: 0;
    }
    &::before {
      @media(max-width: $to-small) {
        display: none;
      }
      content: '';
      display: block;
      background-color: $accent-2;
      border-radius:0 10px 0 0;
      width: rem(100px);
      height: rem(100px);
      position: absolute;
      left: 0;
      top: -10%;
    }

  }

  &__header {
    padding: rem(150px) 0;

    &-content {
      padding: 0 rem(30px);
    }

  }

  &-main {
    &__background {
      position: relative;
      overflow: hidden;
      left: -7vw;
      width: calc(100% + 14vw);
      height: 15rem;

      .-full & {
        width: calc(100% + 18rem);
        border-radius: 0;
      }


      @media(min-width: $from-tiny) {
        left: -10vw;
        width: calc(100% + 20vw);

      }

      @media(min-width: $from-small) {
        left: -9rem;
        height: 28rem;
        width: calc(100% + 18rem);

      }

      @media(min-width: $from-medium) {
        height: 45rem;
        width: calc(100% + 9rem);
        border-radius: 0 10px 0 0;
      }
    }

    &__content {
      
      background-color: #ffb71c;
      padding: rem(50px);

      position: relative;
      left: -7vw;
      width: calc(100% + 14vw);
      z-index: 1;

      

      @media(min-width: $from-tiny) {
        left: -10vw;
        width: calc(100% + 20vw);
      }

      @media(min-width: $from-small) {
        width: calc(100% + 18rem);
        left: -9rem;
      }

      @media(min-width: $from-medium) {
        padding: rem(100px);
        border-radius: 5px 0 0 5px;


      }

    }

    &__cta {

      @media(min-width: $from-tiny) {
        left: -10vw;
        width: calc(100% + 20vw);
        padding: rem(60px);
      }

      @media(min-width: $from-small) {
        width: calc(100% + 18rem);
        left: -9rem;
      }

      @media(min-width: $from-medium) {
        width: calc(100% + 9rem);
        padding: rem(90px);
        left: initial;

      }

      left: -7vw;
      width: calc(100% + 14vw);
      position: relative;

      padding: rem(30px);
      background-color:#F9F9F9;
      text-align: center;

      a {
        margin: 0 auto;
      }
    }

  }
  .hide-mobile {
    @media(max-width: $to-medium) {
      display: none;
    }
  }
}
.c-image-box-translate {
  @media(min-width: $from-medium) {
    margin-bottom: -3rem;
        z-index: 9999;
        position: relative;

  }
  position: relative;

  &::before {

    content: '';
    background-image: url('../../assets/images/home/bubbles.svg');
      position: absolute;
      left:  rem(30px);
      top: rem(-120px);
      background-size: contain;
      width:  rem(450px);
      height: rem(450px);
  }

}