.l-layout {
  &__content {
    &-sub {
      .-center & {
        justify-content: center;
      }
      .-dark & {
        color: #fff;
      }
      font-size: rem(12px);
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: rem(10px);
      &::after {
        content: "";
        margin-left: rem(10px);
        background-color: $accent;
        display: block;
        width: rem(90px);
        height: 3px;
      }
    }
    &-title {
      .-dark & {
        color: #fff;
      }

      &.-small {
        font-size: rem(32px);
      }
      font-size: rem(41px);
      @media(min-width: $from-medium){

        font-size: rem(51px);
      }
      font-family: $font-title;
      font-weight: bold;
      margin-bottom: rem(25px);
      line-height: normal;
      color: $accent-3;

      &--wrap {
        position: relative;
        overflow: hidden;
        margin-bottom: rem(60px);
        

        .l-layout__content-title {
          transform: translate3d(0,100%,0);
          transition: .8s cubic-bezier(.215,.61,.355,1);
          margin-bottom: 0;
          // padding-bottom: rem(60px);

          
        }

        &.is-inview {
          .l-layout__content-title {
            transform: none;
          }
        }
      }

      

      
     

      // @media(max-width: $to-medium) {
      //   font-size: rem(44px);
      // }

      .-period,
      .-highlight {
        color: $accent;
      }
      .-break {
        display: block;
      }
      .-italic {
        font-style: italic;
        display: block;
        font-weight: normal;
      }
    }

    &-list {
      line-height: 2;
      font-weight: normal;
      .-dark & {
        color: #fff;
      }
    }

    &-listing {
      line-height: 2;
      font-weight: bold;
      padding-left: rem(50px);
      list-style-type: disc;
      display: block;
      margin-bottom: rem(10px);

      &.-deaux {
        @media(min-width: $from-small) {
          columns: 2;

        }
      }
      
    }

    &-text {
      .-dark & {
        color: #fff;
      }
      line-height: 2;
      font-weight: normal;

      margin-bottom: rem(10px);

      // @media(max-width: $to-medium) {
      //   font-size: 16px;
      // }

      a {
        font-weight: bold;
        color: $accent;
      }
    }

    &.-center {
      text-align: center;
    }
    &.-left {
      text-align: left;
    }
  }
}
