[data-namespace="interior"] {

  &[data-page="faq"] {
    .c-main-hero__background .o-background {
      @media(min-width: $from-medium) {
        background-size: 40%;

      }

    }

    .button-grid {
      display: flex;
      flex-wrap: wrap;

      &>a {
        width: 100%;
        @media(min-width: $from-small) {
          
          flex-grow: 1;
          width: calc(33% - 6px);
          margin: 6px;
        }

      }
    }
  }

  .c-main-hero__background {
    width: 100%;
    height: calc(50% - 3rem);
    top: initial;
    bottom: 0;

    @media(min-width: $from-medium) {
      width: 55%;
      right: 0;
      left: initial;
      height: 100%;
      height: calc(100% - 12rem);
    }

    @media(min-width: $from-huge) {
      width: 40%;
    }

    .o-background {
      background-position: center center;
      background-size: 150px;

      @media(min-width: $from-medium) {
        background-size: 60%;


      }
    }



  }

  .c-main-hero {

    &__content {
      // @media(min-width: 1200px) {
      //   margin-left: rem(50px);

      // }
    }

    &__title {
      color: $color;

      // font-weight: normal;
      &-bg {
        background-color: $accent-2;
      }
    }

    &__subtitle {
      margin-top: rem(30px);
      background-color: $accent-2;
      position: relative;
      border-radius: 5px;

      &::after {
        content: '';
        display: block;
        background-image: url(../../assets/images/gooe.svg);
        width: rem(80px);
        height: rem(20px);
        position: absolute;
        background-repeat: no-repeat;
        background-size: contain;
        right: 20%;
        bottom: rem(-20px);
        z-index: 1;
      }

    }

    &__subtitle-content {
      color: $color;
      padding: rem(10px);

      @media(min-width: $from-medium) {
        text-align: left;

      }

      p {
        margin: 0 auto;
      }
    }
  }

  .scroll-text {
    color: $color;

  }

  .scroll-arrow svg {
    fill: $color;
  }
}

[data-namespace="crm-jobs"] {
  .c-main-hero__inner {
    padding-top: 0;
  }

  .c-main-hero__title-bg {
    background-color: $accent-2;
  }


}


// .page-mission-and-values {
//   .c-header-navigation__link {
//     color: #292929;

//     &:hover {
//       color: #292929;
//     }
//     &.-cta-btn {
//       color: #fff;
//     }

//     &.has-drop {
//       svg {
//         fill: red;
//       }
//     }
// }
// }


[data-namespace="crm-jobs"] {
  .c-main-hero__content {
    text-align: center;
  }




  .c-main-hero {
    height: 20vh;
    min-height: 360px;

    @media (min-width: $from-medium) {
      min-height: 30vh;
      height: 400px;



    }

  }
}


[data-component="blurb"] {

  .c-mission__grid {
    margin-top: rem(120px);
  }
}

[data-component="downloads"] {
  .c-accordion {
    padding-top: 0;
  }

  .c-download__content {
    h3 {
      color: $accent;
      font-size: rem(28px);
    }
  }
}

[data-page="corportate"] {
  .c-cta-banner {
    margin-top: rem(120px);
  }

  .c-cta-banner .c-bubbles {
    right: 0;
  }

  [data-component="benefits-perks"] {
    .c-accordion {
      @media(max-width: $to-small) {
        padding: 0;
      }
    }
  }

  [data-component="blurb"] {
    .c-blurb {
      @media(max-width: $to-small) {
        margin-top: rem(60px);
      }
    }
  }
  [data-component="our-mission"] {
    @media(max-width: $to-small) {
      margin-top: rem(90px);
    }
  }

  [data-component="image-info"] {
    @media(max-width: $to-small) {
      margin-top: rem(90px);
    }
  }

  [data-component="blurb"] {
    .c-blurb-image__background-wrapper {
      @media(max-width: $to-small) {
        margin-top: rem(20px);
      }
    }

    .c-blurb__layout-item {
      @media(max-width: $to-small) {
        margin-top: rem(30px);
      }
    }
  }


}


[data-page="mission-values"] {
  [data-component="diversity"] {
    margin-top: rem(220px);
  }

  [data-component="blurb-image"] {
    @media(max-width: $from-medium ) {
      .c-blurb-image,
      .c-blurb-image__background-wrapper {
        margin-top: 0;
      }
      .c-blurb-image__content-logo {
        margin-top: rem(60px);
      }
    }
  }

  @media(max-width: $to-medium) {
  [data-component="values"] .c-blurb {
    margin-top: 0;
  }
  }
}


@media(max-width: $from-medium ) {
  .-mobile-flip {
    display: flex !important;
    flex-flow: column-reverse !important;
  }
}

[data-page="training"] {
  .c-cta-banner {
    margin-top: rem(90px);

    @media(max-width: $to-small) {
      margin-top: 0;


    }
  }

  @media(max-width: $to-small) {
    [data-component="blurb"] .c-mission__grid {
      padding-bottom: 0;
    }

  }



}

