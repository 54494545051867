.c-image-info {

  

  .rmhc-logo {
    max-width: 100%;
  }

  #ctu & {
    @media(max-width: $to-large) {
     
      .c-image-info__wrap {
        padding: rem(60px) 0 0 0;
        border-radius: 0;
        box-shadow: none;
        left: 0;
      }
    }
    @media(max-width: $to-medium) {
      background: #fff;
    }
  }

  #rmhc {
    @media(max-width: $to-medium) {
      padding: 0;
      box-shadow: none;
    }
  }

  .hide-mobile {
    @media(max-width: $to-medium) {
      display: none;
    }
  }

  &.-has-bg {
    padding-bottom: rem(150px);
    @media(min-width: $from-medium) {
      padding-bottom: rem(300px);

    }
    margin-bottom: 0;

    &::before {
      content: '';
      display: block;
      width: 100%;
      position: absolute;
      bottom: rem(-120px);
      height: 100%;
      background-image: url('../../assets/images/home/FlattenedArches.svg');
      background-repeat: no-repeat;
      background-size: cover;
      z-index: -1;


    }
  }

  &.show {
    overflow: visible;

    @media(max-width: $to-medium) {
      margin-top: rem(60px);
    }
  }

  position: relative;
  margin: rem(180px) 0;
  overflow: hidden;

  &__logos {
    padding: rem(30px) 0;
    text-align: center;
    position: relative;

    @media(min-width: $to-large) {
      left: -6rem;
      padding: rem(90px) 0;

     
      
    }
    img {
      max-width: 100%;
    }
  }

  &.-border {

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      width: rem(190px);
      z-index: -1;
      height: calc(100% + 9rem);
      background-color: $accent-2;
      border-radius: 0 5px 5px 0;
      transform: translateX(-50%) translateY(-50%);
    }
  }


  &__layout {
    @media (max-width: $to-medium) {
      flex-flow: column;

      &.-mobile-flip {
        flex-flow: column-reverse;
      }
    }

    &-item {
      &.pl-0 {
        @media(min-width: $from-medium) {
          padding-left: 0 !important;

        }
      }
    }
  }

  &__inner {
    position: relative;

    .-has-cube & {
      &::before {
        content: "";
        display: block;
        position: absolute;
        height: 9rem;
        width: 9rem;
        right: -9rem;
        top: -9rem;
        background-color: $accent;
      }
    }
  }

  &__wrap {

    [data-page="our-people"] & {
      margin-bottom: rem(90px);
    }


    .-border & {




      @media(min-width: $to-medium) {
        left: -6rem;
        // width: calc(100% + 12rem);
      }

    }

    background-color: #fff;
    box-shadow: 10px 6px 20px 6px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding: rem(60px);

    @media(max-width: $from-small) {
      padding: rem(60px) rem(30px)
    }

    position: relative;
    z-index: 2;

    .c-bubbles {
      position: absolute;
      top: rem(-90px);
      left: rem(-60px);
      will-change: transform;
    }
  }

  &__background {
    position: relative;
    overflow: hidden;
    right: 7vw;
    width: calc(100% + 14vw);

    height: 15rem;

    @media(min-width: $from-small) {
      border-radius: 10px 0 0 10px;

    }

    &-wrap {
      position: relative;
      display: block;

      &::after {
        content: '';
        display: block;
        background-color: $accent;
        width: 50%;
        height: rem(10px);
        position: absolute;
        bottom: rem(-10px);
        right: -9rem;
        border-radius: 0 0 0 10px;
      }
    }

    .-flipped & {
      left: -7vw;
    }

    .-has-cube &,
    .-mobile-flip &,
    .-spacer & {
      margin-top: rem(60px);

      @media (min-width: $from-medium) {
        margin-top: 0;
      }
    }

    @media (min-width: $from-tiny) {
      .-flipped & {
        left: -10vw;
      }

      right: 10vw;


      width: calc(100% + 20vw);
    }

    @media (min-width: $from-small) {
      .-flipped & {
        left: -9rem;
      }

      right: -9rem;
      height: rem(360px);

      width: 100%;
    }

    @media (min-width: $from-medium) {
      height: rem(500px);

      [data-namespace="interior"] & {
        height: rem(560px);
      }
    }
  }

  &__cta {
    width: calc(100% + 14vw);
    left: -7vw;

    @media (min-width: $from-tiny) {
      width: calc(100% + 20vw);
      left: -10vw;
    }

    @media (min-width: $from-small) {
      width: auto;
      left: auto;
      margin-top: -4rem;
    }

    background-color: $bg;
    z-index: 1;
    position: relative;
    color: #fff;
    padding: rem(60px);

    p {
      font-size: rem(21px);
      font-weight: 600;
    }
  }

  &__list {
    padding: rem(30px) 0;

    @media(min-width: $from-medium) {
      width: auto;
      max-width: 100%;

    }

    &-item {
      margin-bottom: rem(30px);

      @media(min-width: $from-small) {
        display: flex;
        align-items: flex-start;

      }

    }

    &-img {
      // width: 60px;
      margin-bottom: rem(30px);

      svg {

        // &.lappy {
        // width: rem(90px);
        // height: rem(90px);
        // }
        @media(max-width: $from-small) {
          width: rem(80px);
          height: rem(80px);
        }

        width: rem(180px);
        height: rem(80px);
      }

      img {
        width: rem(80px);
        max-width: 100%;
      }
    }

    &-content {
      font-weight: bold;
      width: 100%;

      @media(min-width: $from-small) {
        margin-left: rem(30px);
      }

      h3 {
        color: $accent;
        font-weight: bold;
        margin-bottom: rem(10px);
      }
    }
  }
}


.c-image__background {
  position: relative;
  overflow: hidden;


  height: rem(330px);
  border-radius: 10px;

  &-wrap {
    position: relative;
    display: block;

    &.-normal {
      display: none;

      @media(min-width: $from-large) {
        display: block;
        margin-top: rem(120px);

        &::after {
          content: '';
          display: block;
          background-color: $accent;
          width: calc(100% + 30px);
          margin: 0 auto;
          height: rem(120px);
          position: absolute;
          bottom: rem(-10px);
          left: -15px;
          border-radius: 10px;
          z-index: -1;
        }
      }

    }

    &.-small {
      display: none;

      @media(min-width: $from-medium) {
        display: block;
        margin-top: rem(90px);
        width: rem(250px);
      }


      .c-image__background {
        height: rem(200px);

      }

      &.-bump {
        position: relative;
        padding-top: rem(90px);

        &::after {
          content: '';

          width: 50%;

          bottom: rem(-10px);
          left: initial;
          right: rem(-15px);

        }
      }

      &::after {
        content: '';
        display: block;
        background-color: $accent;
        width: calc(100% + 30px);
        margin: 0 auto;
        height: rem(120px);
        position: absolute;
        bottom: rem(-10px);
        left: -15px;
        z-index: -1;
        border-radius: 10px;
      }
    }

    &.-video {

      // padding-top: rem(90px);
      @media(min-width: $from-medium) {

        width: rem(600px);

      }

      &::after {
        content: '';
        display: block;
        background-color: $accent;
        width: 10%;
        margin: 0 auto;
        height: rem(220px);
        position: absolute;
        bottom: rem(-10px);
        right: -15px;
        border-radius: 10px;
        z-index: -1;
      }


    }


  }


}



.c-image-info__video {
  position: absolute;
  display: block;
  left: 1.875rem;
  bottom: 1.875rem;
  width: auto;
  z-index: 2
}

.c-image-info__video-btn {
  display: block;
  padding: 1rem;
  position: relative;
  border-radius: 100%;
  // background-color: $accent-2;
}

.c-image-info__video-btn:before,
.c-image-info__video-btn:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid $accent-2;
  border-radius: 50%;
  opacity: 0;
  z-index: -1
}

.c-image-info__video-btn:before {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-animation: pulsey 2s infinite linear;
  animation: pulsey 2s infinite linear
}

.c-image-info__video-btn:after {
  -webkit-animation: pulsey 2s 1s infinite linear;
  animation: pulsey 2s 1s infinite linear
}

.c-image-info__video-ring {
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

.c-image-info__video-ring svg {
  width: 2rem;
  height: 2rem;
  fill: #fff
}

@-webkit-keyframes pulsey {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 0
  }

  33% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }

  100% {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
    opacity: 0
  }
}

@keyframes pulsey {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 0
  }

  33% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1
  }

  100% {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
    opacity: 0
  }
}

.c-image-info-slider {

  





  
    width: calc(100% + 18rem);
    left: -9rem;
    margin-bottom: rem(-30px);
    display: block;
    position: relative;

    &__background {
      width: 100%;
      height: rem(350px);
      border-radius: 10px;
    }
  

  .swiper-pagination {
    display: none; 

    @media(min-width: $from-medium) {
      display: block;
      padding-top: rem(90px);
      position: relative;
      right: 9rem;
      width: calc(100% + 9rem);
      margin: 0 auto;
    }

    .swiper-pagination-bullet-active {
      background-color: $color;
    }
  }


}

.c-image-info .c-accordion {
  padding: 0
}